/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import List from "@mui/material/List";
import {
  Box,
  Typography,
  Grid,
  Button,
  Modal,
  FormControl,
  InputAdornment,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import ManIcon from "@mui/icons-material/Man";
import ListItem from "@mui/material/ListItem";
import WarningIcon from "@mui/icons-material/Warning";
import Container from "@mui/material/Container";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import {useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import RepeatSharpIcon from "@mui/icons-material/RepeatSharp";
import Tooltip from "@mui/material/Tooltip";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";

import laptop from "../../assets/img/laptop.png";
import Secure_login from "../../assets/img/Secure_login_pana_1.png";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {Header} from "../../commonComponent/Header/header";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  getColour,
  peopleRiskContent,
  processRiskContent,
  riskLevel,
  systemRiskContent,
} from "../utils";

import "./style.css";
import {requestCallback} from "../api/callbackApi";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Result = () => {
  const navigate = useNavigate();
  const numberPattern = /^[0-9]+$/;
  const result = useSelector((state) => state.surveyReducer.result);
  const [personScore, setPersonScore] = useState(25);
  const [systemScore, setSystemScore] = useState(25);
  const [processScore, setprocessScore] = useState(25);
  const [isIdkSatisfied, setIsIdkSatisfied] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const warning = `You answered "I am not sure" to more than 3 questions. Kindly re-attempt the test with more information for a more accurate score`;

  const [open, setOpen] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => {
    requestCallback(userInfo)
      .then((res) => setIsError(false))
      .catch((err) => setIsError(true));
    setOpen2(true);
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    if (result) {
      setPersonScore(result.personScore);
      setSystemScore(result.systemScore);
      setprocessScore(result.processScore);
    } else {
      navigate("/");
    }
  }, [result]);

  useEffect(() => {
    const answers = JSON.parse(localStorage.getItem("answers"));
    const userInfo = JSON.parse(
      JSON.parse(localStorage.getItem("user-details"))
    );
    if (userInfo) {
      setUserInfo({
        name: userInfo.name,
        phone: userInfo.phone,
        companyName: "",
      });
    }
    if (answers) {
      const filterAnswers = answers.filter((ans) => ans.selectedValue === "P");
      if (filterAnswers.length > 2) {
        setIsIdkSatisfied(true);
      }
      setTimeout(function () {
        removeLocalStorageDetails();
      }, 500);
    }
  }, []);

  const removeLocalStorageDetails = () => {
    // localStorage.removeItem("user-details");
    localStorage.removeItem("answers");
  };

  const redirect = (link) => {
    window.open(link, "_blank");
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handlePhoneInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (numberPattern.test(value) || value === "") {
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    }
  };

  ValidatorForm.addValidationRule("isPhoneNumberValid", (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length !== 10) {
      return false;
    }

    const firstDigit = parseInt(phoneNumber.charAt(0));
    return firstDigit > 5;
  });

  ValidatorForm.addValidationRule("isFullName", (value) => {
    const trimmedValue = value.trim();
    const nameParts = trimmedValue.split(" ");

    return (
      nameParts.length >= 2 &&
      nameParts.some((part) => /^[a-zA-Z]{2,}$/.test(part))
    );
  });

  return (
    <React.Fragment>
      <Header />
      <Box className="report-page">
        <Typography variant="h5" className="report-title">
          Your Cybersecurity Risk Level
        </Typography>
        {isIdkSatisfied && (
          <Typography className="warning">{warning}</Typography>
        )}
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item lg={12} className="main-box">
              <Box className="report-box">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="chart-box">
                      <Box className="tooltip-box">
                        <Typography className="chart-title">People</Typography>
                        <Tooltip
                          title="Addressing cybersecurity from the perspective of
                        personnel & training within the organization"
                        >
                          <IconButton className="tooltip-icon">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box className="chart-progress">
                        <SemiCircleProgressBar
                          background="#8972bc"
                          strokeWidth={20}
                          percentage={personScore}
                          stroke={getColour(personScore)}
                        />
                        <Typography className="chart-percentage">
                          {personScore}%
                        </Typography>
                      </Box>
                      <Box className="chart-icon">
                        <ManIcon fontSize="large" />
                      </Box>
                      <Box className="chart-risk">
                        <Typography component={"span"}>
                          {riskLevel(personScore)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="chart-box systemsCard">
                      <Box className="tooltip-box">
                        <Typography className="chart-title">Systems</Typography>
                        <Tooltip
                          title=" Focusing on the technological infrastructure and
                        defences against cyber threats, encompassing hardware,
                        software, access controls and tools"
                        >
                          <IconButton className="tooltip-icon">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box className="chart-progress">
                        <SemiCircleProgressBar
                          background="#8972bc"
                          strokeWidth={20}
                          percentage={systemScore}
                          stroke={getColour(systemScore)}
                        />
                        <Typography className="chart-percentage">
                          {systemScore}%
                        </Typography>
                      </Box>
                      <Box className="chart-icon">
                        <WarningIcon fontSize="large" />
                      </Box>
                      <Box className="chart-risk">
                        <Typography component={"span"}>
                          {riskLevel(systemScore)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="chart-box processesCard">
                      <Box className="tooltip-box">
                        <Typography className="chart-title">
                          Processes
                        </Typography>
                        <Tooltip
                          title=" Establishing protocols and strategies for handling cybersecurity incidents, risk
                        management, and compliance with regulations"
                        >
                          <IconButton className="tooltip-icon">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box className="chart-progress">
                        <SemiCircleProgressBar
                          background="#8972bc"
                          strokeWidth={20}
                          percentage={processScore}
                          stroke={getColour(processScore)}
                        />
                        <Typography className="chart-percentage">
                          {processScore}%
                        </Typography>
                      </Box>
                      <Box className="chart-icon">
                        <RepeatSharpIcon fontSize="large" />
                      </Box>
                      <Box className="chart-risk">
                        <Typography component={"span"}>
                          {riskLevel(processScore)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={12}>
              <Box className="report-box">
                <Grid container spacing={2} className="report-box-grid">
                  <Grid item md={6} lg={4}>
                    <Box className="laptop-image">
                      <img src={laptop} alt="laptop.png" />
                    </Box>
                  </Grid>
                  <Grid item md={6} lg={8}>
                    <Typography component={"p"} className="report_text">
                      The self-assessment test analyses your risk levels across
                      3 fundamental aspects of cyber security:
                    </Typography>
                    <List className="cardList">
                      <ListItem
                        className="cardItemBox"
                        component="div"
                        disablePadding
                      >
                        <Typography component={"b"}>People :</Typography>
                        <Typography>
                          {peopleRiskContent(personScore)}
                        </Typography>
                      </ListItem>
                      <ListItem
                        className="cardItemBox"
                        component="div"
                        disablePadding
                      >
                        <Typography component={"b"}>Systems :</Typography>
                        <Typography>
                          {systemRiskContent(systemScore)}
                        </Typography>
                      </ListItem>
                      <ListItem
                        className="cardItemBox"
                        component="div"
                        disablePadding
                      >
                        <Typography component={"b"}>Process :</Typography>
                        <Typography>
                          {processRiskContent(processScore)}
                        </Typography>
                      </ListItem>
                    </List>
                    <Typography component={"p"} className="report_box">
                      The risk levels reflected for each pillar are a result of
                      analysing the probability and likely impact of potential
                      threats.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={12}>
              <Box className="report-box">
                <Grid container spacing={2} className="report-box-grid">
                  <Grid item md={6} lg={8}>
                    <Typography
                      component={"p"}
                      className="report_text"
                    ></Typography>
                    <Typography component={"p"} className="report_text">
                      Are you of the assumption that cyber attacks are only
                      targeted towards larger companies?
                    </Typography>
                    <Typography className="report_text">
                      Small businesses and startups in fact make for easier
                      targets due to their lack of adequate security
                      infrastructure
                    </Typography>
                    <Box>
                      <ul className="report_text border">
                        <li>
                          43% of all cyber attacks in India are targeted towards
                          SMEs & startups
                        </li>
                        <li>
                          India is the most targeted country in the world,
                          accounting for 13.7% of all cyber attacks.
                        </li>
                        <li>
                          The average cost of a data breach in India in 2023
                          stood at ₹17.9 Cr, up by 28% since 2020
                        </li>
                      </ul>
                    </Box>
                    <Typography className="report_box">
                      Waiting for disaster to strike is not a plan, insure your
                      business today !
                    </Typography>
                    <Typography className="desclaimer">
                      Disclaimer: The results shown above reflect estimated
                      cyber risk levels as per industry standards based on your
                      responses
                    </Typography>
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <Box className="Secure-image">
                      <img src={Secure_login} alt="Secure_login-pana" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Box className="bottom_box">
            <Box className="understandingbox">
              <Box className="icon_box">
                <a href="#">
                  <ImportantDevicesIcon />
                </a>
              </Box>
              <Box className="understandingtextbox">
                <Box className="texticon_box">
                  <Box>
                    <Typography variant="h3">
                      Do you need help in understanding this survey?
                    </Typography>
                    <Typography>
                      Our team will break this report down and answer any
                      questions you have
                    </Typography>
                  </Box>

                  <Button onClick={handleOpen}>Request A Callback</Button>
                  {/* <Button onClick={handleOpen2}>Request A Callback2</Button> */}
                  {userInfo && (
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="parent-modal-title"
                      aria-describedby="parent-modal-description"
                    >
                      <Box sx={{...style, width: 350}} className="modal_box">
                        <Typography variant="h2" id="parent-modal-title">
                          Request Callback
                        </Typography>
                        <Typography id="parent-modal-description">
                          Please confirm your contact details and out team will
                          reach out soon
                        </Typography>
                        <ValidatorForm onSubmit={handleOpen2}>
                          <Grid container spacing={2} className="row-form">
                            <Grid item lg={12}>
                              <FormControl fullWidth>
                                <TextValidator
                                  fullWidth
                                  className="modal_popop_box"
                                  placeholder="Company Name"
                                  type="text"
                                  name="companyName"
                                  onChange={handleInputChange}
                                  value={userInfo.companyName}
                                  validators={["required"]}
                                  errorMessages={["Company name is required"]}
                                  InputProps={{
                                    style: {
                                      color: "#fff",
                                    },
                                  }}
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <TextValidator
                                  fullWidth
                                  className="modal_popop_box"
                                  placeholder="Full Name"
                                  type="text"
                                  name="name"
                                  onChange={handleInputChange}
                                  value={userInfo.name}
                                  validators={["required", "isFullName"]}
                                  errorMessages={[
                                    "Full name is required",
                                    "Full name is not valid",
                                  ]}
                                  InputProps={{
                                    style: {
                                      color: "#fff",
                                    },
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg={12} className="input-padding">
                              <FormControl fullWidth className="form-input-box">
                                <TextValidator
                                  fullWidth
                                  className="number_input_box modal_popop_box"
                                  placeholder="9xxxxxxxx2"
                                  type="tel"
                                  name="phone"
                                  onChange={handlePhoneInput}
                                  value={userInfo.phone}
                                  validators={[
                                    "required",
                                    "isPhoneNumberValid",
                                  ]}
                                  errorMessages={[
                                    "Contact number is required",
                                    "Contact number is not valid",
                                  ]}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className="number-select"
                                      >
                                        +91
                                      </InputAdornment>
                                    ),
                                    style: {
                                      color: "#fff",
                                    },
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Button type="submit">Submit</Button>
                        </ValidatorForm>
                      </Box>
                    </Modal>
                  )}
                  <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                  >
                    <Box sx={{...style, width: 400}} className="modal_box_two">
                      <a>
                        {isError ? (
                          <CancelIcon
                            sx={{cursor: "pointer"}}
                            onClick={handleClose2}
                          />
                        ) : (
                          <CheckCircleOutlineOutlinedIcon
                            sx={{cursor: "pointer"}}
                            onClick={handleClose2}
                          />
                        )}
                      </a>
                      <p id="parent-modal-description">
                        {isError
                          ? "Something went wrong"
                          : "Our insurance expert will contact you soon."}
                      </p>
                    </Box>
                  </Modal>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Result;
