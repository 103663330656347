import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk("users", async (userData, { extra }) => {
  const response = await axios.post(
    "https://oz4uyz2677.execute-api.ap-south-1.amazonaws.com/user",
    userData
  );
  return response.data;
});
