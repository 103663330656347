import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import "./style.css";

const AnsSelection = (props) => {
  const { handleSelectedAns, currentSurvey } = props;
  const [selected, setSelected] = useState(currentSurvey.selectedValue);

  React.useEffect(() => {
    if (currentSurvey) {
      setSelected(currentSurvey.selectedValue);
    }
  }, [currentSurvey]);

  const handleToggle = (value) => {
    handleSelectedAns(value);
  };

  return (
    <Box className="ansSelection">
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <ToggleButton
            className="ansOption"
            fullWidth
            size="medium"
            value={currentSurvey.selectedValue}
            selected={selected === "Y"}
            onChange={() => handleToggle("Y")}
          >
            Yes
          </ToggleButton>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <ToggleButton
            className="ansOption"
            fullWidth
            value={currentSurvey.selectedValue}
            selected={selected === "N"}
            onChange={() => handleToggle("N")}
          >
            No
          </ToggleButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ToggleButton
            className="ansOption"
            fullWidth
            value={currentSurvey.selectedValue}
            selected={selected === "P"}
            onChange={() => handleToggle("P")}
          >
            I am not sure
          </ToggleButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnsSelection;
