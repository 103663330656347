/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep, isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import LinearProgress from "@mui/material/LinearProgress";

import AnsSelection from "./AnsSelection";
import FooterButton from "./footer";
import animation from "../../assets/img/animation.png";
import characterImage from "../../assets/img/character.png";
import { Header } from "../../commonComponent/Header/header";
import { saveResult } from "../actions/surveyAction";

import "./style.css";

const Survey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let surveryList = useSelector((state) => state.surveyReducer.surveyList);
  const message = useSelector((state) => state.surveyReducer.message);
  const [userData, setUserData] = useState(null);
  const [answeredList, setAnsweredList] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const [currentSurvey, setCurrentSurvey] = useState({});
  const [progress, setProgress] = React.useState(0);
  const [surveySubmitted, setSurveySubmitted] = useState(false);

  useEffect(() => {
    const ansList = loadAnswersFromLocalStorage();
    const user = getUser();
    if (isEmpty(user)) {
      navigate("/");
    } else {
      setUserData(JSON.parse(user));
    }
    if (ansList.length) {
      setAnsweredList(ansList);
    }
  }, []);

  useEffect(() => {
    if (message !== "") {
      navigate("/loader");
    }
  }, [message]);

  useEffect(() => {
    if (
      surveyData.length &&
      surveyData[surveyData.length - 1].selectedValue !== "" &&
      userData &&
      !surveySubmitted
    ) {
      setProgress(100);
      setSurveySubmitted(true);
      setTimeout(function () {
        dispatch(saveResult({ surveyData, id: userData.id }));
      }, 1000);
    }
  }, [surveyData, surveySubmitted]);

  useEffect(() => {
    if (answeredList.length && surveyData.length) {
      let tempSurveyData = cloneDeep(surveyData);
      answeredList.forEach((item) => {
        const matchingItemIndex = tempSurveyData.findIndex(
          (elem) => elem.id === item.id
        );
        if (matchingItemIndex !== -1) {
          tempSurveyData[matchingItemIndex].selectedValue = item.selectedValue;
        }
      });
      setSurveyData(tempSurveyData);
      setCurrentSurvey(tempSurveyData[answeredList.length]);
    }
  }, [answeredList]);

  useEffect(() => {
    if (surveryList.length) {
      setSurveyData(surveryList);
      setCurrentSurvey(surveryList[0]);
    }
  }, [surveryList]);

  useEffect(() => {
    if (currentSurvey) {
      const index = surveyData.indexOf(currentSurvey);
      if (index === 0) {
        setProgress(0);
      } else {
        const progress = (index / 24) * 100;
        setProgress(progress);
      }
    }
  }, [currentSurvey]);

  const getUser = () => {
    const userDetails = localStorage.getItem("user-details");
    return userDetails ? JSON.parse(userDetails) : null;
  };
  const handleNext = () => {
    setCurrentSurvey(surveyData[currentSurvey.id]);
  };

  const handlePrevious = () => {
    setCurrentSurvey(surveyData[currentSurvey.id - 2]);
  };

  const loadAnswersFromLocalStorage = () => {
    const storedQuestions = localStorage.getItem("answers");
    return storedQuestions ? JSON.parse(storedQuestions) : [];
  };

  const saveAnswerToStorage = (questions) => {
    localStorage.setItem("answers", JSON.stringify(questions));
  };

  const saveSurveyResult = (survey, ans) => {
    let answers = loadAnswersFromLocalStorage();
    let findSurvey = answers.find((ans) => ans.id === survey.id);
    if (findSurvey) {
      findSurvey.selectedValue = ans;
    } else {
      let tempSurvey = JSON.parse(JSON.stringify(survey));
      tempSurvey.selectedValue = ans;
      answers.push(tempSurvey);
    }
    saveAnswerToStorage(answers);
  };

  const handleSelectedAns = (ans) => {
    let answeredSurvey = currentSurvey;
    saveSurveyResult(answeredSurvey, ans);

    setSurveyData((prevSurvey) => {
      return prevSurvey.map((survey) =>
        survey.id === currentSurvey.id
          ? { ...survey, selectedValue: ans }
          : survey
      );
    });

    if (surveyData[currentSurvey.id]) {
      setCurrentSurvey(surveyData[currentSurvey.id]);
    }
  };

  return (
    <Fragment>
      <Header />
      {surveySubmitted && <div className="overlay"></div>}
      <Box className="questionPage">
        {currentSurvey && (
          <Box className="modelBox">
            <Box className="questionHeader">
              <Typography variant="h4">
                Question <span>{currentSurvey.id}</span>
              </Typography>
            </Box>
            <span className="question"> {currentSurvey.que} </span>
            <AnsSelection
              handleSelectedAns={handleSelectedAns}
              currentSurvey={currentSurvey}
            />
            <FooterButton
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              currentSurvey={currentSurvey}
              surveyData={[...surveyData]}
            />
          </Box>
        )}
      </Box>
      <Box className="animationBox">
        <Box className="characterBox" sx={{ left: `${progress - 6}%` }}>
          <img src={characterImage} alt="animation" />
        </Box>
        <Box className="progressText">{Math.floor(progress, 2)}%</Box>
        <img
          className="environmentBackground"
          src={animation}
          alt="animation"
        />
        <LinearProgress
          className="progressBar"
          variant="determinate"
          value={progress}
        />
      </Box>
    </Fragment>
  );
};

export default Survey;
