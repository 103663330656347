/* eslint-disable array-callback-return */
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const getExtraScore = (survey) => {
  let personExtraScore = 0;
  let systemExtraScore = 0;
  let processExtraScore = 0;
  survey.map((singleSurvey) => {
    //D03
    if (singleSurvey.id === 2 && singleSurvey.selectedValue === "N") {
      personExtraScore += 5;
      systemExtraScore += 5;
      processExtraScore += 5;
    } else if (singleSurvey.id === 6 && singleSurvey.selectedValue === "N") {
      personExtraScore += 5;
    } else if (singleSurvey.id === 7 && singleSurvey.selectedValue === "N") {
      personExtraScore += 5;
    } else if (singleSurvey.id === 14 && singleSurvey.selectedValue === "N") {
      systemExtraScore += 5;
    } else if (singleSurvey.id === 19 && singleSurvey.selectedValue === "N") {
      processExtraScore += 10;
    } else if (singleSurvey.id === 23 && singleSurvey.selectedValue === "N") {
      processExtraScore += 10;
    }
  });

  console.log(
    "FE console for extra score ==> ",
    personExtraScore,
    systemExtraScore,
    processExtraScore
  );
  return {personExtraScore, systemExtraScore, processExtraScore};
};
export const saveResult = createAsyncThunk(
  "survey",
  async (survey, {extra}) => {
    const result_items = [];
    let extraScores = getExtraScore(survey.surveyData);
    const decideScore = (survey) => {
      if (survey.id === 11 || survey.id === 18) {
        if (survey.selectedValue !== "N") {
          return 1.5;
        } else {
          return 0;
        }
      } else {
        if (survey.selectedValue !== "Y") {
          return 1.5;
        } else {
          return 0;
        }
      }
    };
    survey.surveyData.map((survey) => {
      result_items.push({
        id: survey.id.toString(),
        question: survey.qid,
        category: survey.cid,
        score: decideScore(survey),
        // score: survey.selectedValue === "Y" ? 0 : 1.5,
        // score : (survey.id === 11|| survey.id === 18) && survey.selectedValue === "Y" ? 1.5 : survey.selectedValue === "Y" ? 0 : 1.5
        answer: survey.selectedValue === "Y" ? 1 : 0,
      });
    });
    if (result_items.length) {
      const payload = {
        userId: survey.id,
        surveyData: result_items,
        ...extraScores,
      };
      const response = await axios.post(
        "https://oz4uyz2677.execute-api.ap-south-1.amazonaws.com/result",
        payload
      );
      return response.data;
    }
  }
);
