export let surveyList = [
  {
    id: 1,
    qid: "P1",
    cid: "P",
    categoryScore: 16,
    ans: "N",
    que: "Does your company employ fewer than 100 individuals?",
    selectedValue: "",
  },
  {
    id: 2,
    qid: "P2",
    cid: "P",
    categoryScore: 10,
    ans: "Y",
    que: "Is there a designated person in your organization who handles Cyber Security?",
    selectedValue: "",
  },
  {
    id: 3,
    qid: "P3",
    cid: "P",
    categoryScore: 19,
    ans: "Y",
    que: "Is admin approval necessary for employees to install software on their work computers?",
    selectedValue: "",
  },
  {
    id: 4,
    qid: "P4",
    cid: "P",
    categoryScore: 10,
    ans: "Y",
    que: "Does your organization offer regular Cyber Security training, including best practices and things to avoid?",
    selectedValue: "",
  },
  {
    id: 5,
    qid: "P5",
    cid: "P",
    categoryScore: 10,
    ans: "Y",
    que: "Is access to your company's data restricted to only those who require it for their job?",
    selectedValue: "",
  },
  {
    id: 6,
    qid: "P6",
    cid: "P",
    categoryScore: 16,
    ans: "Y",
    que: "Do you promptly remove access rights for employees who are leaving or changing roles?",
    selectedValue: "",
  },
  {
    id: 7,
    qid: "P7",
    cid: "P",
    categoryScore: 19,
    ans: "Y",
    que: "Is authorization required to make a payment from your company's account?",
    selectedValue: "",
  },
  {
    id: 8,
    qid: "S1",
    cid: "S",
    categoryScore: 10.7,
    ans: "N",
    que: "Is the access to your business systems or company data limited to work PCs only?",
    selectedValue: "",
  },
  {
    id: 9,
    qid: "S2",
    cid: "S",
    categoryScore: 7,
    ans: "N",
    que: "Do you employ any solutions to counter phishing attempts?",
    selectedValue: "",
  },
  {
    id: 10,
    qid: "S3",
    cid: "S",
    categoryScore: 13.3,
    ans: "N",
    que: "Do you have any safeguards in place against Denial of Service (DoS) attacks?",
    selectedValue: "",
  },
  {
    id: 11,
    qid: "S4",
    cid: "S",
    categoryScore: 7,
    ans: "N",
    que: "Does your company manage any IT hardware infrastructure in-house?",
    selectedValue: "",
  },
  {
    id: 12,
    qid: "S5",
    cid: "S",
    categoryScore: 10.7,
    ans: "Y",
    que: "Do you make sure to disable default access credentials on any IT hardware used by your business, such as WiFi routers, servers, etc.? Select Yes if you don't handle any IT hardware internally",
    selectedValue: "",
  },
  {
    id: 13,
    qid: "S6",
    cid: "S",
    categoryScore: 7,
    ans: "N",
    que: "Do you utilize an Intrusion Detection System?",
    selectedValue: "",
  },
  {
    id: 14,
    qid: "S7",
    cid: "S",
    categoryScore: 13.3,
    ans: "N",
    que: "Do you have Antivirus software in use?",
    selectedValue: "",
  },
  {
    id: 15,
    qid: "S8",
    cid: "S",
    categoryScore: 13.3,
    ans: "Y",
    que: "Is there a multi-factor authorization process in place for accessing critical data or systems?",
    selectedValue: "",
  },
  {
    id: 16,
    qid: "S9",
    cid: "S",
    categoryScore: 10.7,
    ans: "N",
    que: "Do you use any solutions that scan emails to eliminate malicious software from messages?",
    selectedValue: "",
  },
  {
    id: 17,
    qid: "S10",
    cid: "S",
    categoryScore: 7,
    ans: "N",
    que: "Do you ensure that the software you use is always updated with the latest patches and updates?",
    selectedValue: "",
  },
  {
    id: 18,
    qid: "R1",
    cid: "R",
    categoryScore: 19,
    ans: "N",
    que: "Does your company handle sensitive customer data like personal identification, medical records, card details, etc.?",
    selectedValue: "",
  },
  {
    id: 19,
    qid: "R2",
    cid: "R",
    categoryScore: 10,
    ans: "N",
    que: "Do you have a contingency plan for Cybersecurity incidents?",
    selectedValue: "",
  },
  {
    id: 20,
    qid: "R3",
    cid: "R",
    categoryScore: 10,
    ans: "N",
    que: "Are you cognizant of the Cybersecurity threats that could potentially affect your business?",
    selectedValue: "",
  },
  {
    id: 21,
    qid: "R4",
    cid: "R",
    categoryScore: 16,
    ans: "N",
    que: "Do you conduct regular assessments to identify potential vulnerabilities?",
    selectedValue: "",
  },
  {
    id: 22,
    qid: "R5",
    cid: "R",
    categoryScore: 10,
    ans: "Y",
    que: "Is there a policy in place that mandates strong passwords and periodic password changes for accessing your business systems?",
    selectedValue: "",
  },
  {
    id: 23,
    qid: "R6",
    cid: "R",
    categoryScore: 19,
    ans: "Y",
    que: "Does your company consistently and reliably back up crucial data?",
    selectedValue: "",
  },
  {
    id: 24,
    qid: "R7",
    cid: "R",
    categoryScore: 16,
    ans: "N",
    que: "Do you have insurance coverage for Cybersecurity incidents?",
    selectedValue: "",
  },
];

export const initialData = {
  isLoading: false,
  message: "",
  surveyList,
  result: null,
};
