import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../reducer/userReducer/index";
import surveyReducer from "../reducer/surveyReducer/index";

export const store = configureStore({
  reducer: {
    userReducer,
    surveyReducer,
  },
});
