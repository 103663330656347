import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./initialstate";
import { saveResult } from "../../actions/surveyAction";

const surveySlice = createSlice({
  name: "survey",
  initialState: initialData,
  extraReducers: (builder) => {
    builder
      .addCase(saveResult.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveResult.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.result = JSON.parse(action.payload.data.body);
      })
      .addCase(saveResult.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default surveySlice.reducer;
