export const peopleRiskContent = (percentage) => {
  if (percentage <= 30) {
    return "Cybersecurity measures are in place with adequate employee awareness";
  } else if (percentage > 30 && percentage <= 65) {
    return "Some gaps in practices, requiring improvement in employee training and access control";
  } else {
    return "Significant deficiencies in measures, demanding immediate attention towards employee training and access control";
  }
};

export const systemRiskContent = (percentage) => {
  if (percentage <= 30) {
    return "Adequate technological defences and access controls are in place";
  } else if (percentage > 30 && percentage <= 65) {
    return "Some vulnerabilities present in the tech infrastructure, require enhancements to cybersecurity solutions and access management";
  } else {
    return "Critical weaknesses in technological defences pose high threat levels and require comprehensive security measures";
  }
};

export const processRiskContent = (percentage) => {
  if (percentage <= 30) {
    return "Adequate protocols for incident response and risk management are in place";
  } else if (percentage > 30 && percentage <= 65) {
    return "Some deficiencies in incident response protocols and risk management strategies, indicate the need for improvements";
  } else {
    return "Lack of effective protocols, requiring immediate establishment of comprehensive processes";
  }
};

export const riskLevel = (percentage) => {
  if (percentage <= 30) {
    return "Low risk";
  } else if (percentage > 30 && percentage <= 65) {
    return "Moderate risk";
  } else {
    return "High risk";
  }
};

export const getColour = (percentage) => {
  if (percentage <= 30) {
    return "#81CA45";
  } else if (percentage > 30 && percentage <= 65) {
    return "#FFC300";
  } else {
    return "#EE4B2B";
  }
};
