import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./initialstate";
import { login } from "../../actions/userAction";

const userSlice = createSlice({
  name: "users",
  initialState: initialData,
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default userSlice.reducer;
