import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import "./footer.css";

const FooterButton = (props) => {
  const { handleNext, handlePrevious, currentSurvey, surveyData } = props;

  const disablePreviousButton = () => {
    const index = surveyData.indexOf(currentSurvey);
    return !index;
  };

  const disableNextButton = () => {
    const getNextSurvey = surveyData[currentSurvey.id];
    if (getNextSurvey) {
      if (
        getNextSurvey.selectedValue === "" &&
        currentSurvey.selectedValue === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Box className="footerButton">
      <Button
        className="previousbutton"
        disabled={disablePreviousButton()}
        size="large"
        type="submit"
        variant="contained"
        onClick={handlePrevious}
        endIcon={
          <KeyboardArrowLeftIcon className="arrow-btn" fontSize="large" />
        }
      ></Button>
      <Button
        disabled={disableNextButton()}
        size="large"
        type="submit"
        variant="contained"
        onClick={handleNext}
        endIcon={<ArrowForwardIosIcon className="arrow-btn" fontSize="large" />}
      ></Button>
    </Box>
  );
};

export default FooterButton;
