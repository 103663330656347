/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { Box, Button, Typography } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router";
import { isEmpty } from "lodash";
import Grid from "@mui/material/Grid";
import { FormLabel } from "@mui/material";

import icon1 from "../../assets/img/Authentication-bro (1) 1.png";
import brandlogo from "../../assets/img/main-logo.png";
import { login } from "../actions/userAction";

import "./style.css";

const Login = () => {
  const numberPattern = /^[0-9]+$/;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer.user);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (!isEmpty(user)) {
      localStorage.setItem("user-details", JSON.stringify(user));
      navigate("/survey");
    }
  }, [user]);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handlePhoneInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (numberPattern.test(value) || value === "") {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleSave = () => {
    dispatch(login(userData));
  };

  ValidatorForm.addValidationRule("isPhoneNumberValid", (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length !== 10) {
      return false;
    }

    const firstDigit = parseInt(phoneNumber.charAt(0));
    return firstDigit > 5;
  });

  ValidatorForm.addValidationRule("isFullName", (value) => {
    const trimmedValue = value.trim();
    const nameParts = trimmedValue.split(" ");

    return (
      nameParts.length >= 2 &&
      nameParts.some((part) => /^[a-zA-Z]{2,}$/.test(part))
    );
  });

  ValidatorForm.addValidationRule("validateEmail", (value) => {
    const emailRegex = /^[a-zA-Z0-9._-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/;

    return emailRegex.test(value);
  });

  return (
    <Box className="login-form">
      <Box className="login-form-image">
        <Box className="login-form-shape">
          <img src={icon1} alt="vector" />
        </Box>
      </Box>
      <Box className="form-box">
        <Box className="loginDataContent">
          <Grid container spacing={1} className="row-grid">
            <Grid item lg={12}>
              <Box className="main-logo">
                <img src={brandlogo} alt="brandlogo" />
              </Box>
            </Grid>
            <Grid item lg={12}>
              <Box className="Information-heading">
                <Typography variant="h2">Enter Basic Information</Typography>
                <Typography>Please Fill Out This Form </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} fullWidth>
              <ValidatorForm onSubmit={handleSave}>
                <Grid container spacing={2} className="row-form">
                  <Grid item lg={12}>
                    <FormControl fullWidth>
                      <FormLabel>Full Name</FormLabel>
                      <TextValidator
                        fullWidth
                        className="input_box"
                        placeholder="Full Name"
                        type="text"
                        name="name"
                        onChange={handleInputChange}
                        value={userData.name}
                        validators={["required", "isFullName"]}
                        errorMessages={[
                          "Full name is required",
                          "Full name is not valid",
                        ]}
                        InputProps={{
                          style: {
                            height: "48px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12}>
                    <FormControl fullWidth>
                      <FormLabel>Contact Number</FormLabel>
                      <TextValidator
                        fullWidth
                        className="input_box"
                        placeholder="9xxxxxxxx2"
                        type="tel"
                        name="phone"
                        onChange={handlePhoneInput}
                        value={userData.phone}
                        validators={["required", "isPhoneNumberValid"]}
                        errorMessages={[
                          "Contact number is required",
                          "Contact number is not valid",
                        ]}
                        InputProps={{
                          style: {
                            height: "48px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12}>
                    <FormControl fullWidth>
                      <FormLabel>Email</FormLabel>
                      <TextValidator
                        fullWidth
                        className="input_box"
                        placeholder="amitabh@covrzy.com"
                        type="email"
                        name="email"
                        onChange={handleInputChange}
                        value={userData.email}
                        validators={["required", "validateEmail"]}
                        errorMessages={[
                          "Email is required",
                          "Email is not valid",
                        ]}
                        InputProps={{
                          style: {
                            height: "48px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12}>
                    <Box className="submit-btn">
                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        endIcon={
                          <ArrowForwardIosIcon
                            className="arrow-btn"
                            fontSize="small"
                          />
                        }
                      >
                        {" "}
                        Start
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
