import axios from "axios"

export const requestCallback = async (data) => {
    try {
        const response = await axios.post("https://covrzy.oneassure.in/api/v1/callback-requests", data);
        // const response = await axios.post("http://143.0.0.104:8001/api/v1/callback-requests", data);
        return response; // Return the response if needed
    } catch (error) {
        throw error; // Throw the error to be caught by the caller
    }
}