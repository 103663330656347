/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Typography} from "@mui/material";
import {useNavigate} from "react-router";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import roundImage from "../../assets/img/round-image3.png";
import icon from "../../assets/img/icon.png";
import {Header} from "../../commonComponent/Header/header";

import "./style.css";

export const Loader = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(function () {
      navigate("/result");
    }, 5000);
  }, []);

  return (
    <Box>
      <Header />
      <Box className="loader-section">
        {/* <Box className="loader-text">
          <Typography variant="h6">Your question has been Submitted</Typography>
        </Box> */}
        <Box className="loader-image">
          <img
            src={roundImage}
            className="arrowAnimation"
            alt="animationArrow"
          />
          <img src={icon} className="icon" alt="right_icon" />
        </Box>
        <Box className="loader-bottom-text">
          <Typography>Calculating your score....</Typography>
        </Box>
      </Box>
    </Box>
  );
};
