import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./components/store";
import Survey from "./components/survey";
import { Loader } from "./components/loader";
import Result from "./components/result";
import Login from "./components/login";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/result" element={<Result />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
